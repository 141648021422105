@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");

.doc-Terms .lst-kix_list_4-1>li {
    counter-increment: lst-ctn-kix_list_4-1;
}

.doc-Terms ol.lst-kix_list_3-1 {
    list-style-type: none;
}

.doc-Terms ol.lst-kix_list_3-2 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_3-1>li {
    counter-increment: lst-ctn-kix_list_3-1;
}

.doc-Terms .lst-kix_list_2-1>li {
    counter-increment: lst-ctn-kix_list_2-1;
}

.doc-Terms ul.lst-kix_list_1-0 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_6-1>li {
    counter-increment: lst-ctn-kix_list_6-1;
}

.doc-Terms .lst-kix_list_3-0>li:before {
    content: "\0025cf  ";
}

.doc-Terms ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 -1;
}

.doc-Terms ul.lst-kix_list_5-7 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_5-8 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_3-1>li:before {
    content: "" counter(lst-ctn-kix_list_3-1, decimal) ". ";
}

.doc-Terms .lst-kix_list_3-2>li:before {
    content: "" counter(lst-ctn-kix_list_3-2, decimal) ". ";
}

.doc-Terms ul.lst-kix_list_5-5 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_5-6 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_1-3 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_3-5>li:before {
    content: "\0025aa  ";
}

.doc-Terms ul.lst-kix_list_5-0 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_1-4 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_1-1 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_3-4>li:before {
    content: "\0025aa  ";
}

.doc-Terms ul.lst-kix_list_1-2 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_5-3 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_1-7 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_3-3>li:before {
    content: "\0025aa  ";
}

.doc-Terms ul.lst-kix_list_5-4 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_1-8 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_5-1 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_1-5 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_5-2 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_ list_1-6 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_3-8>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_3-6>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_3-7>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_3-2>li {
    counter-increment: lst-ctn-kix_list_3-2;
}

.doc-Terms .lst-kix_list_5-0>li:before {
    content: "\0025cf  ";
}

.doc-Terms ol.lst-kix_list_6-1 {
    list-style-type: none;
}

.doc-Terms ol.lst-kix_list_2-1 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_4-8>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_5-3>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_4-7>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_5-2>li:before {
    content: "\0025cf  ";
}

.doc-Terms .lst-kix_list_5-1>li:before {
    content: "\0025cf  ";
}

.doc-Terms ul.lst-kix_list_4-8 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_5-7>li:before {
    content: "\0025aa  ";
}

.doc-Terms ul.lst-kix_list_4-6 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_5-6>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_5-8>li:before {
    content: "\0025aa  ";
}

.doc-Terms ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 -1;
}

.doc-Terms ul.lst-kix_list_4-7 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_4-0 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_5-4>li:before {
    content: "\0025aa  ";
}

.doc-Terms ul.lst-kix_list_4-4 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_5-5>li:before {
    content: "\0025aa  ";
}

.doc-Terms ul.lst-kix_list_4-5 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_4-2 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_4-3 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_6-1>li:before {
    content: "" counter(lst-ctn-kix_list_6-1, decimal) ". ";
}

.doc-Terms .lst-kix_list_6-3>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_6-0>li:before {
    content: "\0025cf  ";
}

.doc-Terms .lst-kix_list_6-4>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_6-2>li:before {
    content: "\0025cf  ";
}

.doc-Terms ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 -1;
}

.doc-Terms .lst-kix_list_6-8>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_6-5>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_6-7>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_6-6>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_2-6>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_2-7>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_2-4>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_2-5>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_2-8>li:before {
    content: "\0025aa  ";
}

.doc-Terms ul.lst-kix_list_3-7 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_3-8 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_3-0 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_3-5 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_3-6 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_3-3 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_3-4 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_4-0>li:before {
    content: "\0025cf  ";
}

.doc-Terms .lst-kix_list_4-1>li:before {
    content: "" counter(lst-ctn-kix_list_4-1, decimal) ". ";
}

.doc-Terms .lst-kix_list_4-4>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_4-3>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_4-5>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_4-2>li:before {
    content: "\0025cf  ";
}

.doc-Terms .lst-kix_list_4-6>li:before {
    content: "\0025aa  ";
}

.doc-Terms ol.lst-kix_list_4-1 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-6 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-7 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-4 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_2-8 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-5 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-8 {
    list-style-type: none;
}

.doc-Terms ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 -1;
}

.doc-Terms ul.lst-kix_list_2-2 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_1-0>li:before {
    content: "\0025cf  ";
}

.doc-Terms ul.lst-kix_list_2-3 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_2-0 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-2 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_2-6 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-3 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_1-1>li:before {
    content: "o  ";
}

.doc-Terms .lst-kix_list_1-2>li:before {
    content: "\0025cf  ";
}

.doc-Terms ul.lst-kix_list_2-7 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_6-0 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_2-4 {
    list-style-type: none;
}

.doc-Terms ul.lst-kix_list_2-5 {
    list-style-type: none;
}

.doc-Terms .lst-kix_list_1-3>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_1-4>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_1-7>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_1-5>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_1-6>li:before {
    content: "\0025aa  ";
}

.doc-Terms li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
}

.doc-Terms .lst-kix_list_2-0>li:before {
    content: "\0025cf  ";
}

.doc-Terms .lst-kix_list_2-1>li:before {
    content: "" counter(lst-ctn-kix_list_2-1, decimal) ". ";
}

.doc-Terms ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 -1;
}

.doc-Terms .lst-kix_list_1-8>li:before {
    content: "\0025aa  ";
}

.doc-Terms .lst-kix_list_2-2>li:before {
    content: "\0025cf  ";
}

.doc-Terms .lst-kix_list_2-3>li:before {
    content: "\0025aa  ";
}

.doc-Terms ol {
    margin: 0;
    padding: 0;
}

.doc-Terms table td,
.doc-Terms table th {
    padding: 0;
}

.doc-Terms .c3 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 10pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c13 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c7 {
    background-color: #ffffff;
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c1 {
    background-color: #ffffff;
    margin-left: 72pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c8 {
    background-color: #ffffff;
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c11 {
    background-color: #ffffff;
    margin-left: 108pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c2 {
    background-color: #ffffff;
    margin-left: 108pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c17 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal;
}

.doc-Terms .c5 {
    color: #3b3b3b;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: "Arial";
    font-style: normal;
}

.doc-Terms .c9 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c4 {
    color: #3b3b3b;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Arial";
    font-style: normal;
}

.doc-Terms .c12 {
    -webkit-text-decoration-skip: none;
    color: #1573bf;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Arial";
}

.doc-Terms .c0 {
    color: #3b3b3b;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Arial";
    font-style: normal;
}

.doc-Terms .c16 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .c10 {
    font-size: 12pt;
    font-family: "Arial";
    color: #3b3b3b;
    font-weight: 400;
}

.doc-Terms .c15 {
    font-size: 12pt;
    font-family: "Arial";
    color: #3b3b3b;
    font-weight: 700;
}

.doc-Terms {
    background-color: #ffffff;
    max-width: 100%;
    padding: 72pt 72pt 72pt 72pt;
}

.doc-Terms .c18 {
    color: inherit;
    text-decoration: inherit;
}

.doc-Terms .c6 {
    padding: 15px 0;
    margin: 0;
}

.doc-Terms .c20 {
    margin-left: 72pt;
}

.doc-Terms .c14 {
    height: 11pt;
}

.doc-Terms .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
}

.doc-Terms p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
}

.doc-Terms h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms h3 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 13.5pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-Terms h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}