@media(min-width: 992px) and (max-width: 1199px){
    .main-menu-wrapper .navbar .navbar-nav li{
        padding: 6px 5px;
    }
    .main-menu-wrapper .navbar .navbar-nav li a{
        font-size: 15px;
    }
}

/*max width 991px*/
@media(max-width: 991px){
	/* header css start */
    .main-menu-wrapper{
        position: relative;
        z-index: 9;
    }
    .main-menu-wrapper .navbar .navbar-collapse{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        padding: 20px 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 50%);
    }
    .main-menu-wrapper .navbar .navbar-nav li{
        text-align: left;
        padding: 8px 5px;
    }
	/* header css end */
    /* right sidebar */
    .fixme.fixed{
        top: auto !important;
    }
    /* right sidebar end */
}

/*width 768px to 991px*/
@media(min-width: 768px) and (max-width: 991px){

}

/*max width 767px*/
@media(max-width: 767px){
    #collapseExample {
        min-width: 100%;
    }
	/* header css start*/
    .top-header .left-location-language ul{
        justify-content: center;
    }
    .top-header .left-location-language ul li:last-child{
        margin-right: 0px;
    }
    .top-header .tophd-link ul{
        padding-left: 0;
        justify-content: center;
    }

    .main-menu-wrapper .navbar .navbar-brand{
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 0px;
    }
    
	/* header css end */

    /*  mid-bx */
    .mid-bx .mid-bx-inner{
        flex-wrap: wrap;
    }
    .mid-bx .mid-bx-inner .rt-content-prt{
        width: 100%;
    }
    /*  mid-bx */
    .dropdown-menu-end[data-bs-popper] {
        right: inherit;
        width: calc(100% + 70%) !important;
    }
    .dropdown-item {
        padding: 5px;
    }
    .logreg-btn-wrap .dropdown-item,
    .logreg-btn-wrap .dropdown-item a {
        text-wrap: balance;
    }
}

/*width 576px to 767px*/
@media(min-width: 576px) and (max-width: 767px){

}

/*max with 575px*/
@media(max-width: 575px){
    .news-bx .news-bx-inner{
        flex-wrap: wrap;
    }
	.news-bx .news-bx-inner .news-img{
        width: 100%;
        margin-right: 0px;
        margin-bottom: 5px;
    }
    .news-bx .news-bx-inner .news-img img{
        width: 100% !important;
    }
    .news-bx .news-bx-inner .news-bx-content{
        position: relative;
        width: 100%;
    }

    .footer-menu li{
        width: 100%;
        padding: 5px 10px;
        border-right: 0px;
    }
    .card-wrapper .card-view{
        flex-wrap: wrap;
    }
    .card-wrapper .card-view .info.info-left{
        width: 100%;
    }

    /* my profile css */
    .custom-fld{
        width: 100%;
    }
    /* my profile css */
}