@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
/*font-family: 'Dancing Script', cursive;*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/*font-family: 'Inter', sans-serif;*/

body {
  font-family: "Inter", sans-serif;
  color: #5c5c5c;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  background-color: #f0f0f0 !important;
  font-weight: 400;
}

* {
  border: medium none;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none !important;
  outline: none !important;
}

a:hover {
  text-decoration: none;
  outline: none;
}

textarea {
  resize: none;
}

p {
  line-height: 26px;
  font-size: 14px;
  font-weight: 400;
  color: #535151;
}

.container {
  max-width: 1400px !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
}

/*#######################*/

/*###### Comon css start ######*/
.btn {
  position: relative;
  padding: 8px 15px;
  border-radius: 5px;
  border-radius: 0 !important;
}

.btn-warning {
  position: relative;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #ffbf00;
  border-radius: 0 !important;
}

.btn-outline-warning {
  border-radius: 5px;
  border: 1px solid #ffbf00;
  padding: 8px 15px;
}

.btn-outline-warning:hover {
  border: 1px solid #ffbf00;
  background-color: #ffbf00;
}

.hide {
  opacity: 0;
  display: none;
  transition: opacity 0.5s linear;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  -ms-transition: opacity 0.5s linear;
}

/*###### Comon css end ######*/

.top-header {
  position: relative;
  width: 100%;
  background-color: #f0f0f0;
}

.top-header .left-location-language {
  position: relative;
  width: 100%;
}

.top-header .left-location-language ul {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
  padding: 0 !important;
}

.top-header .left-location-language ul li {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 0 5px;
  margin: 0;
}

.top-header .left-location-language ul li .fa {
  color: #ffbf00;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.top-header .left-location-language ul li select {
  background-color: transparent;
  border: 0px;
  width: 140px;
}

.top-header .tophd-text {
  position: relative;
  width: 100%;
  text-align: center;
}

.top-header .tophd-text h4 {
  position: relative;
  width: 100%;
  color: #ff3d00;
  font-size: 30px;
  font-weight: 700;
  font-family: "Dancing Script", cursive;
}

.top-header .tophd-link {
  position: relative;
  width: 100%;
}

.top-header .tophd-link ul {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0px;
}

.top-header .tophd-link ul li {
  position: relative;
  padding-left: 20px;
}

.top-header .tophd-link ul li:last-child:before {
  content: "";
  position: absolute;
  left: 9px;
  top: 5%;
  width: 2px;
  height: 90%;
  background-color: #b9b9b9;
}

.top-header .tophd-link ul li .btn-warning {
  position: relative;
  padding: 10px 15px;
}

.top-header .tophd-link ul li a {
  position: relative;
  color: #000000;
}

.main-menu-wrapper {
  background-color: #fff;
}

.main-menu-wrapper .navbar {
  position: relative;
  width: 100%;
}

.main-menu-wrapper .navbar .navbar-nav {
  position: relative;
}

.main-menu-wrapper .navbar .navbar-nav li {
  position: relative;
  padding: 6px 18px;
  text-align: center;
}

.main-menu-wrapper .navbar .navbar-nav li a {
  color: #000000;
  font-size: 16px;
}

.main-menu-wrapper .navbar .navbar-nav li a.active {
  color: #ff3d00;
}

.main-menu-wrapper .navbar .logreg-btn-wrap {
  position: relative;
}

.main-menu-wrapper .navbar .logreg-btn-wrap ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
  display: flex;
  margin-bottom: 0px;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding-left: 10px;
}

.main-menu-wrapper .navbar .logreg-btn-wrap ul li {
  /* padding-left: 10px; */
  padding-right: 10px;
}

.main-menu-wrapper .navbar .logreg-btn-wrap ul li a {
  color: #000000;
  font-size: 16px;
}

.main-menu-wrapper .navbar .logreg-btn-wrap ul li .hed-cart {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding-left: 10px;
}

.main-menu-wrapper .navbar .logreg-btn-wrap ul li .hed-cart i {
  font-size: 22px;
}
.main-menu-wrapper .navbar .logreg-btn-wrap ul li button{
  background: transparent;
  border: 0px;
}

.hed-cart .count {
  position: absolute;
  right: -10px;
  top: 0;
  background-color: #ffbf00;
  width: 15px;
  height: 15px;
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #000;
  line-height: 15px;
}


.main-wrap {
  padding: 26px 0;
}

.disclaimer-checkbox .form-check-label {
  color: #ff3d00;
  font-size: 18px;
  font-weight: 600;
}

.category-list {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.category-list ul {
  margin: 0;
  padding: 0;
}

.hd3 {
  color: #000;
  font-size: 19px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid #c4c4c4;
}

.form-label {
  font-size: 15px;
}

.right-arrow {
  float: right;
  color: #000;
  font-size: 20px;
  cursor: pointer;
}

.right-arrow i {
  transition: all ease-in-out 0.3s;
}

.fa-angle-down.active {
  transform: rotate(180deg);
}

.category-body {
  position: relative;
  width: 100%;
}

.collapse {
  margin: 0;
  padding: 0;
}

.collapse li {
  position: relative;
  padding: 3px 0px;
  color: #7b7b7b;
  font-size: 17px;
}

.category-body li {
  position: relative;
  padding: 3px 0px;
  color: #7b7b7b;
  font-size: 17px;
}

.cat-checkbox label {
  color: #7b7b7b;
  font-size: 15px;
}

.multi-range-slider {
  border: none !important;
  box-shadow: none !important;
  padding: 20px 0 !important;
}

.multi-range-slider .ruler {
  display: none !important;
}

.multi-range-slider .bar-left {
  background-color: #ebebeb !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 2px 0px !important;
}

.multi-range-slider .bar-right {
  background-color: #ebebeb !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.multi-range-slider .thumb::before {
  background-color: #ffbf00 !important;
  border: solid 2px black !important;
  box-shadow: none !important;
}

.multi-range-slider .bar-inner {
  background-color: #000 !important;
}

.news-bx {
  border: 1px solid #ffbf00;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.news-img {
  width: 85%;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 12px;
}

.news-img img {
  width: 100%;
}

.news-bx h4 {
  font-size: 19px;
  font-weight: 400;
  color: #ff3d00;
  margin: 0;
  padding: 0;
}

.news-bx p {
  font-size: 14px;
  color: #040404;
  margin: 6px 0 0;
  line-height: 20px;
}

.mid-bx {
  padding: 12px 10px 12px 3px;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
}

.lft-img-prt {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  /* height: 338px; */
}

.lft-img-prt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rt-content-prt {
  width: 165%;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  overflow: hidden;
}

.slick-slide {
  padding: 0 8px;
}

.slick-prev,
.slick-next {
  z-index: 9;
  width: 28px !important;
  height: 28px !important;
  background: #fff !important;
  border-radius: 50%;
}

.slick-prev::before,
.slick-next::before {
  color: #ffbf00 !important;
  font-family: FontAwesome !important;
}

.slick-prev::before {
  content: "\f104" !important;
}

.slick-next::before {
  content: "\f105" !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

.hdr-prt {
  padding: 12px;
  background-color: #ffbf00;
}

.hdr-prt h3 {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  margin: 0;
  padding: 0;
}

.cross {
  width: 24px;
  height: 24px;
  position: absolute;
  right: -12px;
  top: -12px;
  z-index: 9;
  background-color: #fff;
  border-radius: 100%;
  display: block;
  padding: 0;
}

.cross img {
  width: 100%;
}

.info-lst {
  margin: 0;
  padding: 0;
}

.info-lst li {
  font-size: 14px;
  margin-bottom: 4px;
  float: none;
  font-weight: 400;
  color: #7b7b7b;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-lst li span.info {
  color: #000;
}

.pricing {
  font-size: 17px;
  color: #000;
  font-weight: 500;
  margin: 20px 0 0;
  padding: 0;
  text-align: right;
}

.pricing em {
  font-style: normal;
  color: #ffbf00;
}

.pricing span {
  font-size: 14px;
  font-weight: 300;
  color: #7b7b7b;
  display: block;
}

.feedback-lst {
  margin: 0;
  padding: 0 0 0 10px;
  display: flex;
  flex-wrap: wrap;
}

.feedback-lst li {
  font-size: 17px;
  color: #929292;
  font-weight: 400;
  margin-right: 25px;
  float: left;
  display: flex;
  align-items: center;
}

.feedback-lst li:last-child {
  margin-right: 0;
}

.feedback-lst li i {
  margin-right: 6px;
  color: #5e5e5e;
  transition: all ease-in-out 0.3s;
}

.feedback-lst li span {
  position: relative;
  margin-right: 6px;
  display: inline-flex;
  transition: all ease-in-out 0.3s;
}

.feedback-lst li span img.hvr-img {
  opacity: 1;
}

/* .feedback-lst li span:before{
	content: "";
	position: absolute;
	left:0;
	top:0;
	background: url('../public/images/ico1.png') no-repeat;
	width: 23px;
	height: 23px;
	opacity: 0;
} */

.like {
  color: #28a207 !important;
}

.dislike {
  color: #f00 !important;
}

.avg {
  color: #f8bf01 !important;
}

.feedback-lst li:hover i {
  color: #28a207;
}

.feedback-lst li:hover:nth-child(2) span img.hvr-img {
  opacity: 0;
}

.feedback-lst li:hover:nth-child(3) i,
.feedback-lst li.active:nth-child(3) i {
  color: #f00;
}

.feedback-lst li:hover:nth-child(4) i,
.feedback-lst li.active:nth-child(4) i {
  color: #e453d5;
}

.feedback-lst li:hover:nth-child(5) i,
.feedback-lst li.active:nth-child(5) i {
  color: purple;
}

.feedback-lst li:hover:nth-child(6) i,
.feedback-lst li.active:nth-child(6) i {
  color: orange;
}

.feedback-lst li:hover:nth-child(7) i,
.feedback-lst li.active:nth-child(7) i {
  color: #3a55e0;
}
.feedback-lst a {
  color: #333;
}
.feedback-lst a:hover {
  color: #3a55e0;
}

.list-bx {
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 5px;
  margin: 15px 0 0 7px;
}

.list-bx ul {
  margin: 0;
  padding: 0;
  margin: 10px;
}

.list-bx ul li {
  float: none;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding-left: 20px;
  position: relative;
  margin: 4px 0;
}

.list-bx ul li:before {
  content: "\f05d";
  position: absolute;
  left: 0;
  top: 0;
  font-family: Fontawesome;
  font-size: 15px;
  color: #28a207;
}

.list-bx p {
  font-size: 14px;
  color: #535151;
  margin: 0 0 12px;
}

.list-bx p span {
  font-size: 15px;
  font-weight: 600;
  color: #28a207;
  display: block;
}

.footer {
  background-color: #000;
  padding: 35px 0;
  text-align: center;
}

.footer-menu {
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;
}

.footer-menu li {
  display: inline-block;
  float: none;
  font-size: 16px;
  font-weight: 400;
  padding: 0 30px;
  line-height: 16px;
  border-right: 1px solid #949aa7;
}

.footer-menu li a {
  color: #fff;
}

.social-lnk {
  margin: 30px 0 0;
  padding: 0;
  display: inline-block;
  width: 100%;
}

.social-lnk li {
  display: inline-block;
  float: none;
  margin: 0 8px;
}

.social-lnk li i {
  font-size: 18px;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transition: all ease-in-out 0.3s;
  line-height: 35px;
  text-align: center;
  background: #707070;
}

.copyright {
  color: #fff;
  font-size: 16px;
  margin: 30px 0 0;
}

.pagination {
  display: inline-block !important;
}

.pagination li.page-item {
  float: none !important;
  display: inline-block !important;
}

.page-link {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
  font-size: 18px !important;
  border-radius: 3px;
}

.pagination li.active .page-link {
  background-color: #fff !important;
  border: 1px solid #ffbf00 !important;
  color: #000 !important;
}

.main-title {
  font-size: 40px;
  font-weight: 600;
  color: #000;
  margin: 0;
  padding: 0;
  text-align: center;
}

.provider-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}

.provider-form h3 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin: 0 0 15px;
  padding: 0;
  display: flex;
  align-items: center;
}

.form-check-input:checked {
  background-color: transparent !important;
  border-color: #d5d5d5 !important;
}

.form-check-input {
  position: relative;
  width: 17px !important;
  height: 17px !important;
}

.form-check-input::before {
  position: absolute;
  content: "";
  left: 3px;
  top: 3px;
  width: 10px;
  height: 9px;
  background-color: #ffbf00;
  border-radius: 3px;
  opacity: 0;
}

.form-check-input:checked:before {
  opacity: 1;
}

.disclaimerbox {
  width: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
  background: #2125297a;
  scroll-behavior: auto;
  color: white;
  padding: 5%;
}

.disclaimerrow {
  width: 100%;
  height: 100%;
  display: block;
  scroll-behavior: auto;
  color: white;
  margin: auto;
  padding: 5%;
  overflow: scroll;
  text-align: left;
  background: #141619;
}

.disclaimerrow p {
  color: white;
}

/*##### login register page css start #######*/

.sign-form-wrap {
  position: relative;
  width: 100%;
  padding: 25px 25px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 32%);
  border-radius: 5px;
}

.sign-form-wrap .login-title {
  position: relative;
  width: 100%;
  text-align: center;
}

.sign-form-wrap .login-title .log-logo {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.sign-form-wrap .login-title h3 {
  position: relative;
  width: 100%;
  color: #060606;
  font-size: 35px;
  margin-bottom: 10px;
  font-family: "Marcellus", serif;
}

.sign-form-wrap .signin-form {
  position: relative;
  width: 100%;
}

.sign-form-wrap .signin-form .form-group {
  position: relative;
  width: 100%;
}

.sign-form-wrap .signin-form .form-group label {
  position: relative;
  font-size: 16px;
  color: #0d0c25;
  font-weight: 500;
  margin-bottom: 4px;
}

.sign-form-wrap .signin-form .form-group .form-control {
  position: relative;
  height: auto;
  padding: 5px 8px;
  padding-left: 0;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #707070;
}

.sign-form-wrap .signin-form .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
}

.sign-form-wrap .signin-form .form-group.submit-btn-wrap {
  position: relative;
  width: 100%;
}

.sign-form-wrap .signin-form .form-group.submit-btn-wrap ul {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sign-form-wrap .signin-form .form-group.submit-btn-wrap ul li {
  margin-right: 10px;
}

.sign-form-wrap .signin-form .form-group.submit-btn-wrap ul li p {
  position: relative;
  width: 100%;
  color: #03021c;
  font-size: 15px;
  margin-bottom: 5px;
}

/*##### login register page css end #######*/

.form-select {
  position: relative;
}

.form-select span {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #f00;
  border-radius: 4px;
  z-index: 9;
}

/*blog sidebar fixed scroll css start*/
.fixme {
  width: 350px;
  max-width: 350px;
}

.fixme.fixed {
  position: fixed;
}

@media (max-width: 991px) {
  .fixme {
    width: 100%;
    max-width: 100%;
  }

  .fixme.fixed {
    position: relative;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .fixme {
    width: 227px;
    max-width: 227px;
  }
}

@media (min-width: 1400px) {
  .fixme {
    width: 340px;
    max-width: 340px;
  }
}

@media (min-width: 1600px) {
  .fixme {
    width: 346px;
    max-width: 346px;
  }
}

/*blog sidebar fixed scroll css end*/

.nav-tabs {
  border: none !important;
}

.nav-tabs li {
  background-color: #ffbf00 !important;
  border: 0 !important;
  color: #000;
}

.nav-tabs li button {
  background-color: #ffbf00 !important;
  border: 0 !important;
  color: #000 !important;
  padding: 8px 20px;
}

.ps-x {
  padding-left: 12px !important;
}

/*erotic body css*/

.filter-part {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.filter-part h4 {
  font-size: 15px;
  color: #040404;
  margin: 0 0 10px;
  padding: 0;
  font-weight: 600;
}

.sort-buttons .item {
  color: #3b3b3b;
}

svg {
  overflow: hidden;
}

.f-s-12 {
  font-size: 12px !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.rt-filter-prt {
  width: 44%;
}

.rt-filter-prt .form-select {
  width: 130px;
  margin-right: 15px;
}

.rt-filter-prt a.btn-success {
  font-size: 13px;
  padding: 6px 10px;
}

.card-wrapper {
  flex: 1 1 100% !important;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 100% !important;
  border-bottom: 1px solid #c4ccd5;
  padding: 0 0 10px;
}

.card-view {
  justify-content: space-between;
}

.card-view .info.info-left {
  align-items: flex-start;
  display: flex;
  padding-right: 35px;
}

.card-view .image-wrapper {
  flex-shrink: 0;
  margin-right: 20px;
  max-width: 86px;
  position: relative;
  width: 100%;
  height: 82px;
}

.image-wrapper img {
  height: 100%;
  left: 0;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  word-break: break-all;
}

.color-link {
  color: #2d7bcd !important;
}

.info h6 {
  margin: 0;
}

.info p {
  margin: 0;
  color: #3b3b3b !important;
}

.logreg-btn-wrap .dropdown .dropdown-toggle {
  color: unset;
  background: unset;
  border: none;
  box-shadow: none;
  color: #111111;
  /* font-size: 14px; */
}

.logreg-btn-wrap .dropdown .dropdown-toggle:focus,
.logreg-btn-wrap .dropdown .dropdown-toggle:active {
  box-shadow: none;
  color: #b74244;
  transition: 0.2s;
  background: unset;
}

.logreg-btn-wrap .dropdown-item,
.logreg-btn-wrap .dropdown-item a {
  /* font-size: 14px; */
  display: block;
  color: #111111;
  background: unset;
}

.logreg-btn-wrap .dropdown-item i {
  font-size: 16px;
  width: 20px;
  height: 16px;
  text-align: center;
  line-height: 16px;
}

.logreg-btn-wrap .dropdown-item:focus,
.logreg-btn-wrap .dropdown-item:hover .logreg-btn-wrap .dropdown-item a:focus,
.logreg-btn-wrap .dropdown-item a:hover {
  color: #b74244;
  background: unset;
}

.logreg-btn-wrap .dropdown-item.active,
.logreg-btn-wrap .dropdown-item:active .logreg-btn-wrap .dropdown-item a.active,
.logreg-btn-wrap .dropdown-item a:active {
  color: #b74244;
  text-decoration: none;
  background: unset;
}

/*##### Dashboard part #######*/

/* .dashboard-prt .container {
	max-width: 1140px !important;
} */

.sidebar {
  background-color: #ffffff;
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.sidebar ul {
  margin: 0;
  padding: 0;
}

.sidebar ul li {
  margin: 0 0 10px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
}

.sidebar ul li:last-child {
  margin: 0;
  padding: 0;
  border-bottom: none;
}

.sidebar ul li a {
  display: flex;
  align-items: center;
}

.sidebar ul li a {
  font-size: 18px;
  font-weight: 400;
  color: #5c5c5c;
}

.sidebar ul li a.active {
  color: #ff3d00;
}

.sidebar ul li a .icon_menu {
  background: #fdf5dd;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  margin-right: 12px;
  box-shadow: 0 3px 6px rgba(83, 108, 167, 0.16);
}

.sidebar ul li a.active .icon_menu {
  background: #ffbf00;
}

.sidebar ul li a .icon_menu i {
  font-size: 16px;
  color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidebar ul li a.active .icon_menu i {
  color: #fff;
}

.title1 {
  font-size: 28px;
  font-weight: 700;
  color: #ff3d00;
  margin: 0;
  padding: 0;
}

.lft-profile-prt {
  background-color: #fdf5dd;
  padding: 15px;
  text-align: center;
}

/* ######### Profilr pic upload ########## */

.file-upload {
  width: 180px;
  height: 180px;
  position: relative;
  display: inline-block;
}

.file-upload-input {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 110%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  left: 0;
  right: 0;
}

.image-upload-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0;
  top: 0;
  overflow: hidden;
  border: 8px solid #7039f7;
}

.file-upload-content {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 8px solid #7039f7;
}

.file-upload-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-upload-btn {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: -22px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0de7ff;
  text-align: center;
}

.file-upload-btn i {
  font-size: 20px;
  color: #000;
  line-height: 50px;
}

.drag-img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.drag-img img {
  width: 100%;
  height: 100%;
}

/* ######### END Profilr pic upload ########## */

.btn-subscribe {
  padding: 8px 20px;
  font-size: 17px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  border-radius: 20px;
  background-color: #ffbf00;
  margin: 15px 0 0;
}

.btn-subscribe:hover {
  color: #fff;
}

.btn-subscribe:disabled {
  cursor: not-allowed;
  color: #3a3f44 !important;
  pointer-events: all !important;
  background-color: #eaeaef !important;
  border-color: #dcdce4 !important;
}

.custom-fld {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #cacdcf;
  width: 48%;
}

.custom-fld h4 {
  font-size: 17px;
  font-weight: 400;
  color: #3a3f44;
  margin: 0;
  padding: 0;
}

.custom-fld h4 span {
  font-size: 20px;
  color: #000;
  display: block;
}

.pro-table tr td img {
  width: 100px;
}

/* ############ Product Listing ################ */

.product-bx {
  margin-bottom: 25px;
  background-color: #fff;
  padding-bottom: 10px;
  box-shadow: 0 0 6px #ddd;
}

.pro-img {
  display: block;
  width: 100%;
  height: 275px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.pro-img img {
  width: auto !important;
  max-height: 100%;
  position: relative;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  z-index: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.wishlist {
  position: absolute;
  right: 15px;
  top: 15px;
}

.wishlist i {
  color: #c8c8c8;
  font-size: 18px;
}

.pro-img a.btn-cart {
  display: inline-block;
  width: 100%;
  padding: 8px 10px;
  background-color: #ffbf00;
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -36px;
  transition: all ease-in-out 0.3s;
}

.pro-img:hover a {
  bottom: 0;
}

.product-bx h4 {
  font-size: 17px;
  color: #000000;
  margin: 10px 0 0;
  padding: 0 12px;
  font-weight: 600;
  line-height: 30px;
  height: 58px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-bx h4 span {
  color: #ffbf00;
  display: block;
}
.product-bx h5{
  font-size: 17px;
  color: #ffbf00;
  margin: 8px 0 0;
  padding: 0 12px;
  font-weight: 600;
}

/* ########## Cart ########### */

.cart-dtl-bx {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
}

.cart-dtl-bx table th {
  font-size: 18px;
  color: #060606;
  font-weight: 400;
}

.cart-dtl-bx table td,
.cart-dtl-bx table th {
  padding: 1rem;
}

.cart-pro-dtl {
  display: flex;
  align-items: center;
}

.cart-pro-dtl img {
  width: 65px;
  margin-right: 12px;
}

.cart-pro-dtl h4 {
  color: #060606;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.cart-pro-dtl h4 span {
  font-size: 14px;
  display: block;
}

.cart-empty {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
  font-size: x-large;
  font-weight: bold;
}

.table > :not(caption) > * > * {
  padding: 1rem;
}

.coupon-bx {
  position: relative;
  margin: 0;
}

.coupon-bx form {
  display: flex;
}

.coupon-bx input[type="text"] {
  background-color: transparent !important;
  border: 1px solid #d9d9d9;
  color: #020202;
  font-size: 15px;
  font-weight: 400;
  height: 45px;
  line-height: 1;
  margin: 0 8px 0 0;
  outline: medium none;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #ffc107 !important;
  background-color: #000 !important;
}

.accordion-button:not(.collapsed) {
  color: #ffc107 !important;
}

.comment-text {
  word-wrap: break-word;
}

.find-location-bx {
  display: none;
}

.find-location-bx.show {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: none;
  display: block;
}

#collapseExample {
  min-width: 1026px;
  position: absolute;
  z-index: 11;
}

#collapseExample h3::first-letter {
  color: #ffbf00;
  font-size: 25px;
}

#collapseExample h3 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.checkWrap {
  max-height: 330px;
  overflow-y: scroll;
}

/* ########## Product Details ########### */

.menu-dtl-img {
  background-color: #fff;
  padding: 25px;
  height: 500px;
  overflow: hidden;
}

.menu-dtl-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.menu-dtl-txt h3 {
  color: #060606;
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.menu-dtl-txt p {
  color: #060606;
  font-size: 16px;
  font-weight: 300;
  margin: 12px 0 0;
}

.rating {
  display: inline-block;
}

.menu-dtl-txt .price {
  color: #b74244;
  font-size: 30px;
  margin: 12px 0 0;
  display: block;
}

.count-bx {
  display: flex;
  align-items: center;
}

.count-bx button {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  line-height: 30px;
  border: 1px solid #b74244;
  margin: 6px;
  font-size: 22px;
  color: #b74244;
}

.count-bx div {
  width: 30px;
  text-align: center;
}

/* ########## Checkout ########### */

.checkout h2 {
  font-size: 20px;
  color: #313131;
  font-weight: 700;
  margin-bottom: 30px;
  letter-spacing: 2px;
  padding-bottom: 15px;
  border-bottom: 2px solid #ff3c20;
}

.checkout h3 {
  font-size: 16px;
  color: #313131;
  margin-top: 0;
}

.checkout input {
  width: 100%;
  line-height: 50px;
  border: 1px solid #eee;
  margin-bottom: 15px;
  text-indent: 20px;
  font-weight: 400;
  font-size: 16px;
}

.element-table {
  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;
}

.element-table table {
  width: 100%;
}

.element-table tr th {
  border-bottom: 1px solid #eee;
  padding: 0 0 10px;
  font-size: 20px;
  color: #191919;
}

.element-table tr td {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 400;
}

.element-table tr td.shipping {
  color: #ff3c20;
}

.element-table tr td.total {
  color: #ff3c20;
  font-weight: 700;
  font-size: 23px;
}

.pay-faq h2 {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid #ff3c20;
}

.form-check-label {
  display: flex;
  align-items: center;
}

.form-check-label span {
  margin-right: 8px;
}

.form-check-label h4 {
  font-size: 18px;
  margin-left: 8px;
  margin: 0;
}

/* ########## Package ########### */

.pricing-bx {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border-bottom: 4px solid #215a94;
  padding: 0 0 24px;
  transition: all ease-in-out 0.3s;
}

.pricing-bx:hover {
}

.pricing-hdr {
  position: relative;
  background-color: #215a94;
  padding: 16px 20px;
  text-align: center;
}

.pricing-hdr h3 {
  font-size: 52px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding: 0;
}

.pricing-hdr h3 span {
  font-size: 13px;
  color: #9dccfc;
  text-transform: uppercase;
  display: block;
}

.pricing-hdr h3 sup {
  font-size: 25px;
}

.pricing-bx ul {
  padding: 25px 40px;
  margin: 0;
}

.pricing-bx ul li {
  float: none;
  margin: 0 0 10px;
  padding: 0 0 0 20px;
  position: relative;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
}

.pricing-bx ul li:before {
  position: absolute;
  content: "\f178";
  left: 0;
  top: 0;
  font-family: Fontawesome;
  font-size: 14px;
  color: #215a94;
}

.pricing-bx button {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 20px;
  background-color: #215a94;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.rivon {
  position: absolute;
  right: 0;
  top: 15px;
  width: 70px;
  height: 24px;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  background-color: #f00;
  background-image: linear-gradient(
    to right,
    transparent 33%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 66%
  );
  background-size: 300% 100%;
  animation: shine 2s infinite;
}

.rivon:before {
  position: absolute;
  left: -12px;
  top: 0;
  content: "";
  border-top: 12px solid #f00;
  border-left: 13px solid transparent;
}

.rivon::after {
  position: absolute;
  left: -12px;
  bottom: 0;
  content: "";
  border-bottom: 12px solid #f00;
  border-left: 13px solid transparent;
}

@keyframes shine {
  0% {
    background-position: right;
  }
}

.sky-blue .pricing-hdr {
  background-color: #5dc7c9;
}

.sky-blue .pricing-hdr h3 span {
  color: #0b6e70;
}

.sky-blue {
  border-bottom: 4px solid #5dc7c9;
  /* transform: scale(1.2);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1); */
}

.sky-blue button {
  background-color: #5dc7c9;
}

.grn-blue .pricing-hdr {
  background-color: #a1c627;
}

.grn-blue .pricing-hdr h3 span {
  color: #0b6e70;
}

.grn-blue {
  border-bottom: 4px solid #a1c627;
}

.grn-blue a {
  background-color: #a1c627;
}

.grn-yellow .pricing-hdr {
  background-color: #e8bd0d;
}
.grn-yellow .pricing-hdr h3 span {
  color: #0b6e70;
}
.grn-yellow {
  border-bottom: 4px solid #e8bd0d;
}

.grn-yellow a {
  background-color: #e8bd0d;
}

.mid-pera {
  color: #f00;
  text-align: center;
  margin: 0 0 15px;
}
.qr-code {
  margin-right: 30px;
}
.step-lst {
  margin: 0;
  padding: 0;
}
.step-lst li {
  float: none;
  font-size: 19px;
  color: #333;
  font-weight: 400;
  padding-left: 20px;
  position: relative;
  margin: 10px 0;
}
.step-lst li:before {
  content: "\f05d";
  position: absolute;
  left: 0;
  top: 0;
  font-family: Fontawesome;
  font-size: 18px;
  color: #28a207;
}

/* comment form css */
.comment-wrap {
  position: relative;
  width: 100%;
  margin: 0 !important;
  max-height: 200px;
  overflow: auto;
}
.comment-wrap li.comment-list {
  position: relative;
  width: 100%;
  padding-left: 0px;
}

.comment-wrap li {
  position: relative;
  width: 100%;
  padding-left: 15px;
  margin-bottom: 0px !important;
}
.comment-wrap > li {
  padding-left: 0px !important;
}
.comment-wrap li ul {
  position: relative;
  width: 100%;
  margin: 0px !important;
  margin-bottom: 15px;
}
.comment-wrap li::before {
  content: none !important;
}
.comment-wrap > li:last-child .comment-inner {
  border-bottom: 0px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.comment-inner {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}
.comment-inner h4 {
  position: relative;
  width: 100%;
  font-size: 15px;
  color: #1a1a1a;
  margin-bottom: 3px;
}
.comment-inner h4 span {
  color: #7b7b7b;
  font-size: 12px;
}
.comment-inner p {
  position: relative;
  width: 100%;
  font-size: 14px;
  color: #7b7b7b;
  line-height: 20px;
  margin-bottom: 5px;
}
.comment-inner .like-reply {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}
.comment-inner .like-reply ul {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.comment-inner .like-reply ul li {
  position: relative;
  width: auto;
  padding: 0;
  padding-right: 10px;
  margin-bottom: 0px !important;
}
.comment-inner .like-reply ul li .reply-btn {
  background-color: transparent;
  color: #000;
  font-size: 16px;
}
.comment-inner .replies-btn {
  background-color: transparent;
  color: #007af1;
  font-size: 16px;
}
.comment-inner .replies-btn i {
  margin-right: 5px;
}

/* comment form css end */

.category-body ul {
  padding: 0;
}

/*--- /.range-slider ---*/

/*--- /.range-slider ---*/

.modal {
  /* your modal styles here */
  display: none; /* or visibility: hidden; */
}

.modal.visible {
  display: block; /* or visibility: visible; */
  /* additional styles to position and style the modal */
}


/* ########## Wallet ########### */

.balence-bx {
	width: 175px;
	height: 175px;
	border-radius: 50%;
	border: 8px solid #7039F7;
	background-color: #313131;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.balence-bx h2 {
	font-size: 34px;
	font-weight: 700;
	color: #fff;
	margin: 0;
	padding: 0;
}

.balence-bx h2 span {
	font-size: 15px;
	font-weight: 600;
	color: #ffbf00;
	display: block;
}

.b-bx {
	background-color: #fff;
	padding: 12px;
	border-radius: 10px;
	box-shadow: 0 0 6px #929292;
	text-align: center;
}

.b-bx h3 {
	font-size: 25px;
	font-weight: 700;
	color: #28a207;
	margin: 0;
	padding: 0;
	line-height: 30px;
}

.b-bx h3 span {
	font-size: 14px;
	font-weight: 600;
	color: #7039F7;
	display: block;
}

.b-bx h3.clr-red {
	color: #f00;
}

.title2 {
	font-size: 26px;
	font-weight: 700;
	color: #000;
	margin: 0;
	padding: 0 0 12px;
	border-bottom: 1px solid #ddd;
}

.trans-bx {
	background: #f0f0f0;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0 0;
}

.detail {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 1.2em;
}

.image-block {
	margin-right: 16px;
	width: 60px;
	height: auto;
	border-radius: 10px;
}

.detail strong {
	display: block;
	font-weight: 600;
	color: #27173E;
	margin-bottom: 3px;
	font-size: 18px;
}

.detail p {
	font-size: 11px;
	margin: 0;
	color: #958d9e;
	font-weight: 500;
}

.right {
	padding-left: 10px;
}

.right .price {
	font-weight: 700;
	color: #27173E;
	letter-spacing: -0.03em;
}

/* ####new css add 29.23### */

input[type=number].tip-number::-webkit-inner-spin-button, 
input[type=number].tip-number::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.selected-package-rightside{
  position: relative;
  width: 100%;
}
.selected-package-rightside h3{
  position: relative;
  width: 100%;
  color: #000;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid #c4c4c4;
}
.selected-package-rightside .wallet-baln{
  position: absolute;
  width: 100%;
  text-align: right;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}
.selected-package-rightside p{
  position: relative;
  width: 100%;
  color: #7b7b7b;
  font-size: 14px;
  margin-bottom: 10px;
  
}
.selected-package-rightside p span{
  color: #000;
  font-weight: 600;
}

/* .rt-content-prt.create-ads-content-prt */
.rt-content-prt.create-ads-content-prt{
  width: 200%;
}
.cum-wrap{
  position: relative;
  width: 100%;
}
.cum-wrap .cum-inner-wrap{
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 15px 15px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}
.cum-wrap .cum-inner-wrap h5{
  position: relative;
  width: 100%;
  color: #000;
  font-size: 16px;
  margin-bottom: 5px;
}
.cum-wrap .cum-inner-wrap h5 span{
  color: #7b7b7b;
}
.cum-wrap .cum-inner-wrap p{
  position: relative;
  width: 100%;
  color: #7b7b7b;
  font-size: 15px;
  margin-bottom: 5px;
}

.recent-transactions-inner-wrap {
  position: relative;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

/* ####new css add 29.23 end ### */


.range-max-value {
  padding-left: 10rem;
}

/* ########## Screen Hobbyists Tab ########### */

.top-srch {
	position: relative;
	width: 400px;
	margin: 0;
}

.top-srch input[type="text"] {
	background: #fff;
	border: 1px solid #CDCDCD;
	border-radius: 0;
	color: #313131;
	font-size: 16px;
	font-weight: 400;
	height: 50px;
	line-height: 1;
	margin: 0;
	outline: medium none;
	padding: 3px 8px 3px 35px;
	width: 100%;
}

.top-srch .search-icon {
	background-color: transparent;
	color: #000;
	border: 0 none;
	font-size: 20px;
	height: 50px;
	padding: 0;
	position: absolute;
	left: 10px;
	top: 0;
}

.rate-bx {
  box-shadow: 0 2px 6px #ddd;
  background-color: #fff;
  overflow-y: scroll;
  height: 225px;
  margin-bottom: 25px;
}

.comment-lst {
	padding: 12px;
	border-top: 1px solid #ddd;
	margin: 0 0 20px;
}

.comment-lst li {
	float: none;
	padding: 8px;
	border-bottom: 1px solid #ddd;
	font-size: 16px;
	font-weight: 400;
	color: #03021C;
}

.comment-lst li:last-child {
	border: none;
}

.comment-lst li i {
	font-size: 16px;
	color: #5C5C5C;
	width: 24px;
	margin-right: 8px;
}

.comment-lst li img {
	margin-right: 8px;
}

.total-rate-bx {
  height: 400px;
  overflow-y: scroll;
}

input[type=number].screen-hobbyist-number::-webkit-inner-spin-button, 
input[type=number].screen-hobbyist-number::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.package-details-wrap p{
  font-size: 16px;
  margin-bottom: 10px;
}
.badge {
  font-size: 16px;
}
.click-package{
  font-size: 26px;
  font-weight: 600;
  margin: 30px 0 20px;
  text-align: center !important;
  text-decoration: none;
  display: block;
}
.click-package a{
  color: #000;
}
.empty-cart{
  width: 400px;
  text-align: center;
  border-radius: 10px;
  margin: 30px auto;
  overflow: hidden;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
   padding: 0;
}
.empty-cart img{
  display: inline-block;
  width: 100%;
}
.empty-cart h4{
  font-size: 26px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
}
.form-label sup{
  font-size: 16px;
  color: #f00;
  top: -1px !important;
}
.descrip{
  height: 100px;
  overflow-y: scroll;
  padding: 12px 0;
}