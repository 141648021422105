@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");

.doc-content ol.lst-kix_list_1-3 {
    list-style-type: none;
}

.doc-content ol.lst-kix_list_1-4 {
    list-style-type: none;
}

.doc-content ol.lst-kix_list_1-5 {
    list-style-type: none;
}

.doc-content ol.lst-kix_list_1-6 {
    list-style-type: none;
}

.doc-content ol.lst-kix_list_1-0 {
    list-style-type: none;
}

.doc-content .lst-kix_list_1-4>li {
    counter-increment: lst-ctn-kix_list_1-4;
}

.doc-content ol.lst-kix_list_1-1 {
    list-style-type: none;
}

.doc-content ol.lst-kix_list_1-2 {
    list-style-type: none;
}

.doc-content ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
}

.doc-content .lst-kix_list_1-1>li {
    counter-increment: lst-ctn-kix_list_1-1;
}

.doc-content ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
}

.doc-content ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
}

.doc-content ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
}

.doc-content .lst-kix_list_1-0>li:before {
    content: "" counter(lst-ctn-kix_list_1-0, upper-roman) ". ";
}

.doc-content ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
}

.doc-content ol.lst-kix_list_1-7 {
    list-style-type: none;
}

.doc-content .lst-kix_list_1-1>li:before {
    content: "" counter(lst-ctn-kix_list_1-1, decimal) ". ";
}

.doc-content .lst-kix_list_1-2>li:before {
    content: "" counter(lst-ctn-kix_list_1-2, decimal) ". ";
}

.doc-content .lst-kix_list_1-7>li {
    counter-increment: lst-ctn-kix_list_1-7;
}

.doc-content ol.lst-kix_list_1-8 {
    list-style-type: none;
}

.doc-content .lst-kix_list_1-3>li:before {
    content: "" counter(lst-ctn-kix_list_1-3, upper-roman) ". ";
}

.doc-content .lst-kix_list_1-4>li:before {
    content: "" counter(lst-ctn-kix_list_1-4, upper-roman) ". ";
}

.doc-content ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
}

.doc-content .lst-kix_list_1-0>li {
    counter-increment: lst-ctn-kix_list_1-0;
}

.doc-content .lst-kix_list_1-6>li {
    counter-increment: lst-ctn-kix_list_1-6;
}

.doc-content .lst-kix_list_1-7>li:before {
    content: "" counter(lst-ctn-kix_list_1-7, upper-roman) ". ";
}

.doc-content .lst-kix_list_1-3>li {
    counter-increment: lst-ctn-kix_list_1-3;
}

.doc-content .lst-kix_list_1-5>li:before {
    content: "" counter(lst-ctn-kix_list_1-5, upper-roman) ". ";
}

.doc-content .lst-kix_list_1-6>li:before {
    content: "" counter(lst-ctn-kix_list_1-6, upper-roman) ". ";
}

.doc-content li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
}

.doc-content ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
}

.doc-content .lst-kix_list_1-2>li {
    counter-increment: lst-ctn-kix_list_1-2;
}

.doc-content .lst-kix_list_1-5>li {
    counter-increment: lst-ctn-kix_list_1-5;
}

.doc-content .lst-kix_list_1-8>li {
    counter-increment: lst-ctn-kix_list_1-8;
}

.doc-content ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
}

.doc-content .lst-kix_list_1-8>li:before {
    content: "" counter(lst-ctn-kix_list_1-8, upper-roman) ". ";
}

.doc-content ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
}

.doc-content ol {
    margin: 0;
    padding: 0;
}

.doc-content table td,
.doc-content table th {
    padding: 0;
}

.doc-content .c3 {
    background-color: #ffffff;
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content .c17 {
    background-color: #ffffff;
    margin-left: 72pt;
    padding-top: 14pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content .c4 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content .c11 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Arial";
}

.doc-content .c6 {
    -webkit-text-decoration-skip: none;
    color: #0070c0;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Arial";
}

.doc-content .c12 {
    color: #3b3b3b;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Arial";
    font-style: normal;
}

.doc-content .c15 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal;
}

.doc-content .c2 {
    color: #3b3b3b;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: "Arial";
    font-style: normal;
}

.doc-content .c8 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
}

.doc-content .c10 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content .c1 {
    color: #3b3b3b;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Arial";
    font-style: normal;
}

.doc-content .c0 {
    font-size: 12pt;
    font-family: "Arial";
    color: #3b3b3b;
    font-weight: 700;
}

.doc-content .c13 {
    font-size: 12pt;
    font-family: "Arial";
    color: #3b3b3b;
    font-weight: 400;
}

.doc-content {
    background-color: #ffffff;
    max-width: 100%;
    padding: 72pt 72pt 72pt 72pt;
}

.doc-content .c5 {
    background-color: #ffffff;
    max-width: 100%;
    padding: 72pt 72pt 72pt 72pt;
}

.doc-content .c16 {
    color: inherit;
    text-decoration: inherit;
}

.doc-content .c9 {
    padding: 15px 0px;
    margin: 0;
}

.doc-content .c7 {
    padding-left: 0pt;
}

.doc-content .c14 {
    margin-left: 108pt;
}

.doc-content .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
}

.doc-content .doc-contentp {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
}

.doc-content h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content h3 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 13.5pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.doc-content h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}